* { box-sizing: border-box; padding: 0; margin: 0; }

body { 
  background-color: #f8f8fa;
  font-family: "Helvetica" ;
  color: #282946;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  max-width: 27rem;
  text-align: center;
  margin: 2rem auto;
}

h1 {
  font-size: 2.5em;
  margin: 3rem auto 1.5rem;
  font-family: 'silka';
}

h2 { 
  font-size: 1.2em;
  color: #2a2a47;
  line-height: 1.7;
  font-weight: 400;
}

p {
  color: #56566E;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.8;
  font-weight: 400;
}

a { color: #4e5ee4; }

.App-body {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.App-notes {
  padding: 34px 0;
  border-top: solid 1px #E1E1E2;
}

@media only screen and (max-width: 45rem) {
  .App { padding: 1rem; }
}
